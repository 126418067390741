import { extendTheme, keyframes } from '@chakra-ui/react';

// Define keyframes for the background animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const theme = extendTheme({
  colors: {
    primary: '#2B6CB0', // Adjusted primary color to a shade of blue
    secondary: '#F6E05E', // Gold color for accents and highlights
    background: '#EDF2F7', // Light blue/grey background for contrast with primary
    gradientFrom: '#2B6CB0', // From color for gradient animations
    gradientTo: '#F6E05E', // To color for gradient animations
  },
  fonts: {
    heading: "'Helvetica Neue', sans-serif",
    body: "'Helvetica Neue', sans-serif",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
          color: 'secondary',
          transition: 'color 0.3s ease-in-out', // Smooth color transition on hover
        },
      },
    },
    Button: {
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'primary' : 'secondary',
          color: props.colorMode === 'dark' ? 'white' : 'gray.800',
          _hover: {
            transform: 'scale(1.05)', // Subtle scaling effect on hover
            bgGradient: 'linear(to-r, primary, secondary)', // Gradient effect on hover
            transition: 'all 0.3s ease-in-out', // Smooth transition
          },
        }),
      },
    },
  },
  styles: {
    global: {
      html: {
        fontSize: '100%',
      },
      body: {
        margin: 0,
        padding: 0,
        color: 'gray.600',
        bg: 'background',
        fontFamily: "'Helvetica Neue', sans-serif",
        bgGradient: 'linear(to-r, gradientFrom, gradientTo)', // Gradient background animation
        backgroundSize: '200% 200%',
        animation: `${gradientAnimation} 10s ease infinite`, // Infinite background animation
      },
      'input, textarea': {
        fontFamily: "'Helvetica Neue', sans-serif",
      },
      a: {
        color: 'primary',
        _hover: {
          textDecoration: 'underline',
          transform: 'scale(1.05)', // Slight scaling on hover
          transition: 'all 0.3s ease-in-out',
        },
      },
    },
  },
});

export default theme;
